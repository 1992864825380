import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PagedResponse} from '../data/paged.response';
import {Letter} from '../data/letter';
import {environment} from '../../../environments/environment';
@Injectable()
export class LetterService {

  constructor(private httpClient: HttpClient) {
  }

  findAll() {
    return this.httpClient.get<PagedResponse<Letter>>(`${environment.api_url}/api/letters`);
  }

  deleteLetter(id: string) {
    return this.httpClient.delete<any>(`${environment.api_url}/api/letters/${id}`);
  }

  findById(id: string) {
    return this.httpClient.get<Letter>(`${environment.api_url}/api/letters/${id}`);
  }

  newLetter(value: any) {
    return this.httpClient.post<Letter>(`${environment.api_url}/api/letters`, value);
  }

  updateLetter(id: string, value: any) {
    return this.httpClient.put<Letter>(`${environment.api_url}/api/letters/${id}`, value);
  }
  findByRegarding(param) {
    return this.httpClient.get<any>(`${environment.api_url}/api/letters?regarding=${param}`);
  }
}
