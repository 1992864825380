import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PagedResponse } from '../data/paged.response';
import { url } from 'inspector';

@Injectable()
export class HandoverService {

  constructor(private httpClient: HttpClient) {
  }

  newHanover(input: any) {

    return this.httpClient.post<any>(`${environment.api_url}/api/handovers`, input);
  }
  updateHanover(id, input: any) {

    return this.httpClient.put<any>(`${environment.api_url}/api/handovers/${id}`, input);
  }
  handovergetbyID(id: any) {
    return this.httpClient.get<any>(`${environment.api_url}/api/handovers/${id}`);
  }
  newComplaintHandover(createHandoverInput: any) {
    return this.httpClient.post(`${environment.api_url}/api/complaint-handover`, createHandoverInput);
  }
  getAllHandoverForComplaints(paginationData?) {
    if (paginationData) {
      let url = `${environment.api_url}/api/complaint-handover?page=${paginationData.page}&size=${paginationData.size}`
      if (paginationData.createdBy) url += `&createdBy=${paginationData.createdBy}`
      if (paginationData.startDate && paginationData.endDate) url += `&startDate=${paginationData.startDate}&endDate=${paginationData.endDate}`
      return this.httpClient.get<PagedResponse<any>>(url);
    }
    return this.httpClient.get<any>(`${environment.api_url}/api/complaint-handover`);
  }
  getAllHandoverForComplaint(id: string) {
    return this.httpClient.get<any>(`${environment.api_url}/api/complaint-handover?complaintId=${id}`);
  }

  deleteComplaintHandover(id: string) {
    return this.httpClient.delete<any>(`${environment.api_url}/api/complaint-handover/${id}`);
  }

  getAllHandovers(paginationData?) {
    if (paginationData) {
      let url = `${environment.api_url}/api/handovers?page=${paginationData.page}&size=${paginationData.size}`
      if (paginationData.createdBy) url += `&createdBy=${paginationData.createdBy}`
      if (paginationData.startDate && paginationData.endDate) url += `&startDate=${paginationData.startDate}&endDate=${paginationData.endDate}`
      return this.httpClient.get<PagedResponse<any>>(url);
    }
    return this.httpClient.get<PagedResponse<any>>(`${environment.api_url}/api/handovers`);
  }

  newIncidentHandover(createHandoverInput: any) {
    return this.httpClient.post(`${environment.api_url}/api/incident-handover`, createHandoverInput);
  }

  newResidentHandover(createHandoverInput: any) {
    return this.httpClient.post(`${environment.api_url}/api/resident-handover`, createHandoverInput);
  }
  getAllHandoverForResident(residentId: string) {
    return this.httpClient.get<any>(`${environment.api_url}/api/resident-handover?residentId=${residentId}`);
  }
  deleteResidentHandover(rhid: string) {
    return this.httpClient.delete<any>(`${environment.api_url}/api/resident-handover/${rhid}`);
  }

  getAllHandoverForIncident(incidentId: string) {
    return this.httpClient.get<any>(`${environment.api_url}/api/incident-handover?incidentId=${incidentId}`);
  }
  getAllHandoverForIncidents(paginationData?) {
    if (paginationData) {
      let url = `${environment.api_url}/api/incident-handover?page=${paginationData.page}&size=${paginationData.size}`
      if (paginationData.createdBy) url += `&createdBy=${paginationData.createdBy}`
      if (paginationData.startDate && paginationData.endDate) url += `&startDate=${paginationData.startDate}&endDate=${paginationData.endDate}`
      return this.httpClient.get<any>(url);
    }
    return this.httpClient.get<any>(`${environment.api_url}/api/incident-handover`);
  }

  deleteIncidentHandover(ihid: string) {
    return this.httpClient.delete<any>(`${environment.api_url}/api/incident-handover/${ihid}`);
  }

  deleteHandover($event: any) {
    return this.httpClient.delete<any>(`${environment.api_url}/api/handovers/${$event}`);
  }
  getAllHandoverForProgress(progressId: string) {

    return this.httpClient.get<any>(`${environment.api_url}/api/resident-progress-handovers?progressId=${progressId}`);
  }
  getAllHandoverForProgresss(paginationData?) {
    if (paginationData) {
      let url = `${environment.api_url}/api/resident-progress-handovers?page=${paginationData.page}&size=${paginationData.size}`
      if (paginationData.createdBy) url += `&createdBy=${paginationData.createdBy}`
      if (paginationData.startDate && paginationData.endDate) url += `&startDate=${paginationData.startDate}&endDate=${paginationData.endDate}`
      return this.httpClient.get<any>(url);
    }
    return this.httpClient.get<any>(`${environment.api_url}/api/resident-progress-handovers`);
  }
  getAllHandoverForTransfer(paginationData?) {
    if (paginationData) {
      let url = `${environment.api_url}/api/resident-transfer-handovers?page=${paginationData.page}&size=${paginationData.size}`
      if (paginationData.createdBy) url += `&createdBy=${paginationData.createdBy}`
      if (paginationData.startDate && paginationData.endDate) url += `&startDate=${paginationData.startDate}&endDate=${paginationData.endDate}`
      return this.httpClient.get<any>(url);
    }
    return this.httpClient.get<any>(`${environment.api_url}/api/resident-transfer-handovers`);
  }
  getAllHandoverForTransfers(id: string) {
    return this.httpClient.get<any>(`${environment.api_url}/api/resident-transfer-handovers?transferId=${id}`);
  }
  
  addHandover(id, type, staffId) {
    
  }
  
  getAllHandover(paginationData){
    if (paginationData) {
      let url = `${environment.api_url}/api/all-handovers?page=${paginationData.page}&size=${paginationData.size}`
      if (paginationData.createdBy) url += `&createdBy=${paginationData.createdBy}`
      if (paginationData.startDate && paginationData.endDate) url += `&startDate=${paginationData.startDate}&endDate=${paginationData.endDate}`
      return this.httpClient.get<PagedResponse<any>>(url);
    }
    return this.httpClient.get<any>(`${environment.api_url}/api/all-handovers`);
  }
}
