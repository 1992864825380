import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PagedResponse} from '../data/paged.response';
import {Invoice} from '../../pages/invoice/invoice';
import {environment} from '../../../environments/environment';
import * as qs from 'querystring';
@Injectable()
export class InvoiceService {

  constructor(private httpClient: HttpClient) {
  }

  findAll() {
    return this.httpClient.get<PagedResponse<Invoice>>(`${environment.api_url}/api/invoices`);
  }

  delete(id: string) {
    return this.httpClient.delete(`${environment.api_url}/api/invoices/${id}`);
  }

  newInvoice(input: any) {
    return this.httpClient.post<Invoice>(`${environment.api_url}/api/invoices`, input);
  }

  updateInvoice(id: string, input: any) {
    return this.httpClient.put<Invoice>(`${environment.api_url}/api/invoices/${id}`, input);
  }

  findById(id: string) {
    return this.httpClient.get<Invoice>(`${environment.api_url}/api/invoices/${id}`);
  }
  findByRegarding(param: any) {
    return this.httpClient.get<PagedResponse<Invoice>>(`${environment.api_url}/api/invoices?${qs.encode(param)}`);
  }
}


