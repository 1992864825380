import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  NbMediaBreakpointsService,
  NbMenuBag,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { RippleService } from '../../../@core/utils/ripple.service';
import { NbAuthService } from '@nebular/auth';
import { UserService } from '../../../@core/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ActivityOfEntityService } from '../../../@core/services/activity.of.entity.service';
import { PagedResponse } from '../../../@core/data/paged.response';
import { MessageService } from '../../../@core/services/message.service';
import { DatePipe } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { MessageCountData } from '../../../pages/messages/message.count.data';
import { ChartService } from '../../../@core/services/chart.service';
import { SupportPlanService } from '../../../@core/services/support.plan.service';
import { SubscriptionService } from '../../../@core/services/subscription.service';
import { TaskListService } from '../../../@core/services/task-list.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  public readonly materialTheme$: Observable<boolean>;
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    // {
    //   value: 'material-cosmic',
    //   name: 'Cosmic',
    // },
    // {
    //   value: 'material-corporate',
    //   name: 'Corporate',
    // },
    {
      value: 'material-light',
      name: 'Default',
    },
    {
      value: 'material-dark',
      name: 'Material Dark',
    },
  ];

  currentTheme = 'material-light';

  userMenu = [
    { title: 'Profile', data: { id: 'profile' } },
    { title: 'Help Video', data: { id: 'tutorialVideo' } },
    { title: 'Log out', data: { id: 'logout' } }
  ];
  privateMessages: NbMenuItem[] = [{ title: '', data: {} }];

  notificationContextItems: NbMenuItem[] = [{ title: '', data: {} }];
  notificationCount: string = '0';

  @ViewChild('notificationAction') notificationAction;
  messageCountData: MessageCountData = new MessageCountData();
  reminderArray: any = [];


  public constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private rippleService: RippleService,
    private nbAuthService: NbAuthService,
    private userService: UserService,
    private toastrService: ToastrService,
    private router: Router,
    private activityOfEntityService: ActivityOfEntityService,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private chartService: ChartService,
    private SupportPlanService: SupportPlanService,
    private subsService: SubscriptionService,
    private taskListService: TaskListService
  ) {
    this.materialTheme$ = this.themeService.onThemeChange()
      .pipe(map(theme => {
        const themeName: string = theme?.name || '';
        return themeName.startsWith('material');
      }));
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.nbAuthService.isAuthenticated()
      .toPromise()
      .then(isAuthed => {
        if (!isAuthed) {
          this.router.navigate(['auth/login']);
          return;
        }
        this.initUser();
        this.initNotifications();
        this.inituserContextMenus();
      })
      .catch(reason => {
        this.toastrService.error('Some error occurred during authentication');
      });

    /* this.userService.getUsers()
       .pipe(takeUntil(this.destroy$))
       .subscribe((users: any) => this.user = users.nick);*/

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => {
        this.currentTheme = themeName;
        this.rippleService.toggle(themeName?.startsWith('material'));
      });
    this.messageService.getUserMessages()
      .toPromise()
      .then(value => {
        if (value.content) {
          value.content.forEach(pm => {
            this.privateMessages.push({
              title: pm.subject,
              data: pm,
            });

          });
          this.privateMessages = this.privateMessages.filter(item => item.data.markedRead != true);

        }
      });

    this.messageService.getMessageCount()
      .toPromise()
      .then(value => {
        this.messageCountData = value;
        // this.toastrService.info('Messages count retrieved');
      })
      .catch(reason => {
        this.toastrService.error('Error in getting message count: ' + reason.message);
      });

  }

  private inituserContextMenus() {
    this.menuService.onItemClick()
      .subscribe((value: NbMenuBag) => {
        if (value.item && value.item.data?.id === 'logout') {
          this.subsService.setfasleInfo();
          this.router.navigate(['auth/logout']);
        } else if (value.item && value.item.data && value.item.data.id && value.item.data.id === 'profile') {
          this.nbAuthService.getToken().toPromise().then(value1 => {
            this.router.navigate(['/pages/users/menuprofile/', value1.getPayload().sub, 'details']);
          });
        } else if (value.item && value.item.data?.id === 'tutorialVideo') {
          window.open('https://www.srspro.com.au/training.html','_blank');
        }

      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  private initUser() {
    this.userService.getUserInfo()
      .toPromise()
      .then(ur => {
        this.user = ur;
      })
      .catch(reason => {
        this.toastrService.error('Some error occurred during user info get.' + reason.message);
      });
  }

  private async initNotifications() {
    await this.chartService.reminderBowel()
      .toPromise()
      .catch(reason => {
        this.toastrService.error('Error in getting notifications.');
      })
      .then((value) => {
        this.reminderArray[0] = value?.bowelData;
        this.reminderArray[0] = this.reminderArray[0].filter(item => item.type !== 'CEASED');
      });

    await this.chartService.reminderWound()
      .toPromise()
      .catch(reason => {
        this.toastrService.error('Error in getting notifications.');
      })
      .then((value) => {
        this.reminderArray[1] = value?.woundData;
      });

    await this.SupportPlanService.SupportPlanReminder()
      .toPromise()
      .catch(reason => {
        this.toastrService.error('Error in getting notifications.');
      })
      .then((value: PagedResponse<any>) => {
        this.reminderArray[2] = value?.content;
      });
    await this.taskListService.getNotifications('overdueTasks')
      .toPromise()
      .catch(reason => {
        this.toastrService.error('Error in getting notifications.');
      })
      .then((value) => {
        this.reminderArray[3] = value;
        this.reminderArray[3] = this.reminderArray[3].filter((task) => {
          if (task.assigneeId) {
            return task?.assigneeId?.id == this.user?.id
          }
          else {
            return task
          }
        })
      });
    await this.taskListService.getNotifications('activeTasks')
      .toPromise()
      .catch(reason => {
        this.toastrService.error('Error in getting notifications.');
      })
      .then((value) => {
        this.reminderArray[4] = value;
        this.reminderArray[4] = this.reminderArray[4].filter((task) => {
          if (task.assigneeId) {
            return task?.assigneeId?.id == this.user?.id
          }
          else {
            return task
          }
        })
      });

    const nots = [... this.reminderArray[0], ... this.reminderArray[1], ...this.reminderArray[2], ...this.reminderArray[3], ...this.reminderArray[4]];
    this.notificationAction.badgeText = `${nots.length}`;
    this.notificationAction.badgeStatus = 'basic';
    if (nots.length > 0) {
      this.notificationAction.badgeStatus = 'danger';
    }
    // this.notificationCount = nots.length;
    this.notificationContextItems.pop();
    this.reminderArray[0].forEach(value1 => {
      this.notificationContextItems.push({
        title: 'Bowel Movement Overdue for ' + value1.residentFirstName + ' ' + value1.residentSurname,
      });

    });
    this.reminderArray[1].forEach(value1 => {
      this.notificationContextItems.push({
        title: `Wound Overdue for ${value1.residentFirstName} ${value1.residentSurname}`,
        data: value1,
      });
    });
    this.reminderArray[2].forEach(value1 => {
      this.notificationContextItems.push({
        title: `${value1.message.split(',')[0]}`,
        data: value1,
      });


    });
    this.reminderArray[3].forEach(value1 => {
      const dueDate = new Date(value1.dueDate);
      const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: '2-digit' };
      const formattedDueDate = dueDate.toLocaleDateString('en-GB', options);
      this.notificationContextItems.push({
        title: `Task ${value1.taskName} due on ${formattedDueDate} is now overdue`,
        data: value1,
      });
    })
    this.reminderArray[4].forEach(value1 => {
      const dueDate = new Date(value1.dueDate);
      const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: '2-digit' };
      const formattedDueDate = dueDate.toLocaleDateString('en-GB', options);
      this.notificationContextItems.push({
        title: `Task ${value1.taskName} due on ${formattedDueDate} has been added`,
        data: value1,
      });
    })


  }
  get profilePicUrl() {
    return (this.user?.profilePic) ? `${environment?.api_url}/api/files/${this.user?.profilePic?.id}/serve?X-TenantID=${environment.tenant_id}` :
      'http://ssl.gstatic.com/accounts/ui/avatar_2x.png';
  }

}
