import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ResidentPharmacy} from '../data/resident.pharmacy';
import {PagedResponse} from '../data/paged.response';

@Injectable()
export class ResidentPharmacyService {
  constructor(private httpClient: HttpClient) {
  }

  findById(id: string) {
    return this.httpClient.get<ResidentPharmacy>(`${environment.api_url}/api/pharmacy-forms/${id}`);
  }

  newResidentPharmacy(createPayload: any) {
    return this.httpClient.post<ResidentPharmacy>(`${environment.api_url}/api/pharmacy-forms/`, createPayload);
  }

  updateResidentPharmacy(id: string, updatePayload: any) {
    return this.httpClient.put<ResidentPharmacy>(`${environment.api_url}/api/pharmacy-forms/${id}`, updatePayload);
  }

  findByResidentId(residentId: string) {
    return this.httpClient.get<PagedResponse<ResidentPharmacy>>(`${environment.api_url}/api/pharmacy-forms?residentId=${residentId}`);
  }
  getAllPharmacy() {
    return this.httpClient.get<PagedResponse<ResidentPharmacy>>(`${environment.api_url}/api/pharmacy-forms`);
  }
  delete(id: string) {
    return this.httpClient.delete(`${environment.api_url}/api/pharmacy-forms/${id}`);
  }
}
