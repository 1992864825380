import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PagedResponse } from '../data/paged.response';
import { User } from '../data/users';
import { CreateUserInput } from '../data/create.user.input';
import { CreateRosterInput } from '../data/create.roster.input';
import { UpdateUserInput } from '../data/update.user.input';
import { Observable } from 'rxjs';

@Injectable()
export class UserService {

  constructor(private httpClient: HttpClient) {
  }
  public getUserInfo() {
    return this.httpClient.get<User>(`${environment.api_url}/api/user-info`);
  }

  getAllUsers() {
    return this.httpClient.get<PagedResponse<User>>(`${environment.api_url}/api/user`);
  }

  findById(id: string): Observable<User> {
    return this.httpClient.get<User>(`${environment.api_url}/api/user/${id}`);
  }

  newUser(input: CreateUserInput) {
    return this.httpClient.post<User>(`${environment.api_url}/api/user`, input);
  }

  updateUser(id: string, input: UpdateUserInput) {
    return this.httpClient.put<User>(`${environment.api_url}/api/user/${id}`, input);
  }
  updateUserCerti(id: string, input: any) {
    return this.httpClient.put<User>(`${environment.api_url}/api/user/${id}`, input);
  }

  delete(id: string) {
    return this.httpClient.delete(`${environment.api_url}/api/user/${id}`);
  }
  deleteFileUser(userId: string, id: string) {
    return this.httpClient.delete(`${environment.api_url}/api/user/${userId}/documents/${id}`);
  }

  searchUsers(qs: string) {
    return this.httpClient.get<PagedResponse<User>>(`${environment.api_url}/api/user?name=${qs}`);
  }

  getAllRoster(statrDate: string, endDate: string) {
    return this.httpClient.get<PagedResponse<User>>(`${environment.api_url}/api/staff-roster?rosterStartDate=${statrDate}&rosterEndDate=${endDate}`);
  }
  getAllRosters() {
    return this.httpClient.get<PagedResponse<User>>(`${environment.api_url}/api/staff-roster`);
  }
  newRoster(input) {
    return this.httpClient.post<any[]>(`${environment.api_url}/api/staff-roster`, input);
  }
  InsertManyRoster(input) {
    return this.httpClient.post<any[]>(`${environment.api_url}/api/staff-roster/many`, input);
  }
  getAllhistory(value: boolean) {
    return this.httpClient.get<any[]>(`${environment.api_url}/api/staff-roster-reviews?all=${value}`);
  }
  AddhistoryRoster(id: string, input: any) {
    return this.httpClient.post<User>(`${environment.api_url}/api/staff-roster/${id}/reviews`, input);
  }
  findrosterReviewsById(Id: string) {
    return this.httpClient.get<any[]>(`${environment.api_url}/api/staff-roster/${Id}/reviews`);
  }
  updateRoster(input: CreateRosterInput, id: any) {
    return this.httpClient.put<User>(`${environment.api_url}/api/staff-roster/${id}`, input);
  }
  getActivityEntity(limit) {
    return this.httpClient.get<PagedResponse<User>>(`${environment.api_url}/api/entity-activities?limit=${limit}`);

  }
  deleteRoster(id: string) {
    return this.httpClient.delete(`${environment.api_url}/api/staff-roster/${id}`);
  }
  deleteWithDate(startDate , endDate) {
    return this.httpClient.delete(`${environment.api_url}/api/staff-roster/delete-roster/delete-by-date?endDate=${endDate}&startDate=${startDate}`);
  }
  addHistory(spId: string, payload: any) {

    return this.httpClient.post<PagedResponse<User>>(`${environment.api_url}/api/rent-increase-letter/${spId}/reviews`, payload);
  }

  findReviewsById(spId: string) {
    return this.httpClient.get<any[]>(`${environment.api_url}/api/rent-increase-letter/${spId}/reviews`);
  }

  login(payload){
    return this.httpClient.post(`${environment.api_url}/api/auth/login`, payload)
  }
}
